var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer"},[_c('v-card',[_c('v-card-title',[_vm._v(" Upcoming Customer Expiry "),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isUsersRefreshing},on:{"click":function($event){return _vm.refreshCustomers()}}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[_c('table-filter',{attrs:{"show-date-filter":false,"show-expiry-date-filter":true,"initial-filters":_vm.filters,"label":_vm.filterLabel,"table-name":'customers expiring'},on:{"filter":_vm.applyFilters,"filterLabelChange":_vm.onFilterLabelChange}})],1),_c('v-col',{staticClass:"col-md-3 text-md-right"},[_c('export-button',{attrs:{"title":_vm.exportTitle,"headers":_vm.exportHeaders,"data-endpoint":_vm.constructCompleteCustomersUrl,"orientation":'landscape'}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.searchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-expand-transition',[(_vm.selected.length > 0)?_c('v-row',{staticClass:"mb-2 ml-2"},[_c('v-col',{},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenuDown)+" ")])],1)]}}],null,false,3266966879)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.isSendingPortalCredentialsMessage},on:{"click":function($event){_vm.showPortalCredentialsMessageDialog = true}}},[_c('v-list-item-title',[_vm._v("Send portal credentials message ["+_vm._s(_vm.selected.length)+"]")])],1),(_vm.selected.length > 0 && _vm.can('customer-delete'))?_c('v-list-item',{on:{"click":function($event){_vm.showConfirmBulkDeleteDialog = true}}},[_c('v-list-item-title',[_vm._v(" Delete ["+_vm._s(_vm.selected.length)+"] ")])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"clickable-data-table",attrs:{"headers":_vm.headers,"items":_vm.customers,"loading":_vm.isUsersLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone_number)+" ")]}},{key:"item.account_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.account_number)+" ")]}},{key:"item.internet_connection_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatInternetConnection")(item.customer.internet_connection_type,_vm.internetConnectionTypes))+" ")]}},{key:"item.customer.customer_plan.internet_plan.name",fn:function(ref){
var item = ref.item;
return [(item.customer.customer_plan)?[_c('span',[_vm._v(_vm._s(item.customer.customer_plan.internet_plan.name)+" ("+_vm._s(_vm.formatValidity(item.customer.customer_plan.internet_plan.validity_unit, item.customer.customer_plan.internet_plan.validity))+")")]),(item.customer.has_pending_plan_change)?_c('span',[_vm._v(" (Pending: "+_vm._s(item.customer.pending_plan_change.new_plan.name)+") ")]):_vm._e()]:_vm._e()]}},{key:"item.customer.customer_plan.effective_end_date",fn:function(ref){
var item = ref.item;
return [(item.customer.customer_plan)?_c('span',[_c('p',{staticClass:"mb-2",style:(_vm.getExpiryColor(item.customer.customer_plan.effective_end_date))},[(item.customer.customer_plan.end_date !== item.customer.customer_plan.effective_end_date)?[_c('span',{staticClass:"strikethrough"},[_vm._v(_vm._s(_vm._f("formatDate")(item.customer.customer_plan.end_date)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.customer.customer_plan.effective_end_date)))]),_c('v-tooltip',{attrs:{"max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.expiryDateTooltipMessage(item.customer.customer_plan.end_date, item.customer.customer_plan.effective_end_date))}})])]:[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.customer.customer_plan.end_date))+" ")]],2)]):_vm._e(),(item.customer.customer_plan && item.customer.can_update_plan_expiry)?_c('v-btn',{staticClass:"mb-1",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateExpiry(item)}}},[_c('v-icon',{attrs:{"left":"","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_vm._v(" Update Expiry ")],1):_vm._e()]}},{key:"item.customer.has_internet_access",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.customer.has_internet_access ? 'success' : 'error',"dark":""}},[_vm._v(" "+_vm._s(item.customer.has_internet_access ? 'Yes' : 'No')+" ")])]}},{key:"item.customer.debt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.customer.debt))+" ")]}},{key:"item.customer.station.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.station.name)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-divider')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }