<template>
  <v-row v-if="hasRoutersNeedingBackup">
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <span v-if="routersNeedingBackup.length === 1">
          No automatic backup has been performed in the last 2 days for the router at {{ routersNeedingBackup[0].station_name }} ({{ routersNeedingBackup[0].host }}).<br>
          Last successful backup was {{ routersNeedingBackup[0].last_backup || 'never performed' }}.<br>
          Please investigate the issues to ensure automatic backups can resume.
        </span>
        <template v-else>
          <p>
            The following routers have not been automatically backed up in the last 2 days. Please investigate the issue to ensure automatic backups can resume:
          </p>
          <ul>
            <li
              v-for="router in routersNeedingBackup"
              :key="router.id"
            >
              {{ router.station_name }} ({{ router.host }}) - Last successful backup: {{ router.last_backup || 'Never' }}
              <v-chip
                v-if="router.is_offline"
                x-small
                class="ml-2"
                color="error"
              >
                Offline
              </v-chip>
            </li>
          </ul>
        </template>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RouterBackupAlert',

  data() {
    return {
      hasRoutersNeedingBackup: false,
      routersNeedingBackup: [],
    }
  },

  mounted() {
    this.fetchRoutersNeedingBackup()
  },

  methods: {
    async fetchRoutersNeedingBackup() {
      try {
        const { data } = await axios.get('routers/needs-backup')
        this.routersNeedingBackup = data
        this.hasRoutersNeedingBackup = this.routersNeedingBackup.length > 0
      } catch (error) {
        console.error('Error fetching routers needing backup:', error)
      }
    },
  },
}
</script>

<style scoped>
.v-alert ul {
  margin-bottom: 0;
  margin-top: 8px;
}

.v-alert li {
  margin-bottom: 4px;
}

.v-alert li:last-child {
  margin-bottom: 0;
}
</style>
